.btn-google-login {
  @extend %flex-center;
  gap: rem(10);
  min-width: rem(200);
  height: rem(40);
  font-size: $font-sm;
  border-radius: rem(5);
  border: 0;
  background: #666666;
  color: #fff;
  padding: rem(15);
  svg {
    background-color: #fff;
    border-radius: 50%;
  }
}
