.page-section {
  @extend %flex-center;
  flex-direction: column;
  height: 100vh;
  .page-header {
    text-align: center;
    padding-top: rem(40);
  }
  .page-content {
    text-align: center;
    max-width: 460px;
    margin: 0 auto;
    padding: 30px;
    background-color: #fff;
    p {
      padding: 15px;
      color: #666666;
    }
  }
  &.login-page {
    .page-content {
      .btn-google-login {
        font-size: 14px;
        font-weight: 400;
        margin: auto;
        background-color: #1C9D5B;
      }
    }
  }
}


.page-section.login-page {
  background-image: url('../../assets/images/background.jpg');
  background-size: cover;
  background-position: center;
}

.main-content {
  padding-left: rem(45);
  width: 100%;
  height: 100vh;
  iframe {
    width: 100%;
    height: 100%;
    border: none;
  }
}


.page-footer {
  img {
    margin-bottom: rem(34);
  }
}

.login-card {
  min-height: rem(288);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  box-shadow: 0 0 rem(15) rgba(0, 0, 0, 0.1);
  border-radius: rem(6);
  h1,
  h4 {
    color: #666666;
  }
}

.form-group {
  margin-bottom: 15px;
  text-align: left;
}

label {
  display: block;
  font-size: 14px;
  color: #666666;
  font-weight: 500;
  margin-bottom: 10px;
}

.custom-label{
  display: block;
  font-size: 14px;
  color: #666666;
  font-weight: 600;
  margin-bottom: 20px;
}
.custom-input{
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;

}

input[type="email"],
input[type="password"] {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button[type="submit"] {
  background-color: #1C9D5B;
  color: #fff;
  text-transform: uppercase;
  font-weight: 400;
  padding: 10px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
}

.page-footer {
  text-align: center;
  margin-top: 20px;
}

.page-footer img {
  max-width: 200px;
}

.custom-modal-portal .ReactModal__Overlay--after-open {
  background-color: rgba(228, 228, 228, 0.55) !important;
  z-index: 9999 !important;
}
