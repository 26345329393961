.side-menu {
  position: absolute;
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: rem(240);
  background-color: #f6f3f1;
  .adoption-title {
    color: #666666;
    font-weight: 800;
    width: rem(165);
    height: rem(21);
    margin: rem(19.5) rem(37.5);
  }
  .menu-list {
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #666666;
    width: 100%;
    height: rem(240);
    .menu-item {
      cursor: pointer;
      width: rem(220);
      height: rem(40);
      display: flex;
      align-items: center;
      margin-left: rem(10);
      border-radius: rem(6);
      .item-text {
        margin-left: rem(65);
      }
    }
    .menu-item:hover {
      background-color: #666666;
      color: #fff;
    }
    .home {
      background: url('../images/home.png') no-repeat rem(20) rem(10);
    }
    .adocao {
      background: url('../images/adocao.png') no-repeat rem(20) rem(10);
    }
    .colaboracao {
      background: url('../images/colaboracao.png') no-repeat rem(20) rem(10);
    }
    .info-tecnicas {
      background: url('../images/analise-tecnica.png') no-repeat rem(20) rem(10);
    }
    .produtividade {
      background: url('../images/produtividade.png') no-repeat rem(20) rem(10);
    }
    .comunicacao {
      background: url('../images/comunicacao.png') no-repeat rem(20) rem(10);
    }
    .perfil {
      background: url('../images/perfil.png') no-repeat rem(20) rem(10);
    }
    .convite {
      background: url('../images/convite.png') no-repeat rem(20) rem(10);
    }
    .sair {
      background: url('../images/sair.png') no-repeat rem(20) rem(10);
    }

    .home:hover {
      background-image: url('../images/home-hover.png');
    }
    .adocao:hover {
      background-image: url('../images/adocao-hover.png');
    }
    .colaboracao:hover {
      background-image: url('../images/colaboracao-hover.png');
    }
    .info-tecnicas:hover {
      background-image: url('../images/analise-tecnica-hover.png');
    }
    .produtividade:hover {
      background-image: url('../images/produtividade-hover.png');
    }
    .comunicacao:hover {
      background-image: url('../images/comunicacao-hover.png');
    }
    .perfil:hover {
      background-image: url('../images/perfil-hover.png');
    }
    .convite:hover {
      background-image: url('../images/convite-hover.png');
    }
    .sair:hover {
      background-image: url('../images/sair-hover.png');
    }
  }
}
.sidemenu-divider {
  width: rem(180);
  height: rem(1);
  background: #e5e5e5;
  margin: 0 rem(30) rem(10);
}
