.main {
  margin-left: 60px;
  width: calc(100% - 60px);
  margin-top: 0px;
}

.main h3 {
  width: 200px;
  padding: 12px 0px 12px 10px;
}

.search-box {
  margin: 5px 5px 5px 0px;
  padding: 6px;
  margin-left: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  display: flex;
}

.search-box input {
  margin-right: 10px;
  border: none;
  padding: 5px;
}

.search-box button {
  border: none;
  background: transparent;
  padding: 0;
}

.empresa-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}

.empresa-item {
  width: 100%;
}

.box-empresa-list {
  color: #297c4f;
  margin: 10px;
  padding: 15px;
  box-shadow: 0 0.25rem 0.9375rem rgba(0, 0, 0, 0.1019607843);
  border-radius: 6px;
}

.button-active {
  /* Estilos específicos para o botão "NOVO GRUPO" */
  height: 39px;
  background-color: #1C9D5B;
  color: #fff;
  margin-top: 5px;
  margin-right: 4px;
  padding: 8px 16px;
  font-weight: 600;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
}

.button-active:hover {
  background-color: #237458;
}

.button-cancel {
  /* Estilos específicos para o botão "NOVO GRUPO" */
  height: 39px;
  background-color: #fff;
  color: #297c4f;
  margin-top: 5px;
  margin-right: 4px;
  padding: 8px 16px;
  font-weight: 600;
  border: 2px solid #297c4f;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
}

.button-cancel:hover {
  background-color: #e68a00;
}


.group-box {
  display: flex;
  justify-content: space-between;
  /* Alinha o valor do grupo à esquerda e os botões à direita */
  align-items: center;
  color: #999;
}

.icon-button {
  padding: 50;
  cursor: pointer;
}

.table-content {
  margin: 20px;
}

.new-button {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.label {
  font-weight: bold;
  margin: 10px;
}

.inputField {
  flex: 1;
  /* grow para ocupar todo o espaço disponível */
  margin: 10px;
  /* espaçamento para não grudar os campos uns nos outros */
}

.row {
  display: flex;
  flex-wrap: wrap;
}

.fixedSizeInput1 {
  width: 200px;
  /* largura fixa de 80px para o campo Ano Fiscal */
  margin: 10px;
  /* espaçamento para não grudar os campos uns nos outros */
}

.fixedSizeInput2 {
  width: 300px;
  /* largura fixa de 100px para os campos Início e Fim */
  margin: 10px;
  /* espaçamento para não grudar os campos uns nos outros */
}