.frame {
}

.main {
  margin-left: 60px;
  width: calc(100% - 60px);
  margin-top: 0px;
}
.main h3 {
  padding: 12px 0px 0px 10px;
}

.search-box {
  margin: 5px 5px 5px 0px;
  padding: 6px;
  margin-left: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  display: flex;
}

.search-box input {
  margin-right: 10px;
  border: none;
  padding: 5px;
  }

.search-box button {
  border: none;
  background: transparent;
  padding: 0;
}

.empresa-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}

.empresa-item {
  width: 100%;
}

.button {
  padding: 5px 0px 5px 5px;
  width: 100%;
  background-color: transparent;
  border: none;
  text-align: left;
  border-bottom: 1px solid #ccc;
}
.button:hover {
  background-color: #237458; 
  border-radius: 4px;
  color: #fff;
  font-weight: 600;
}

.box-empresa-list{
  margin: 10px;
  padding: 15px;
  box-shadow: 0 0.25rem 0.9375rem rgba(0, 0, 0, 0.1019607843);
  border-radius: 6px;
}

