.custom-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #FFF;
  color: #666666;
  text-align: center;
  width: 500px;
  padding: 30px;
  transition: opacity 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}
.custom-modal-overlay {
  background-color: #666666;
}
.custom-modal p{
  margin-top: 30px;
}
.custom-modal button {
  width: auto;
  margin-top: 50px;
  background-color: #1C9D5B ;
  color: #FFF;
  font-weight: 600;
  padding: 10px;
  border-radius: 4px;
  border: none;
}

.ReactModal__Overlay {
  background-color: rgb(229 229 229 / 40%);
}
