.header {
  // width: 100vw;
  display: flex;
  // justify-content: flex-end;
  color: #666666;
  box-shadow: 0 rem(4) rem(15) #0000001a;

  .user-data {
    .user-info {
      background-color: aqua;
      padding: 100px;
      display: flex;
      flex-direction: column;
      margin: rem(20);
      font-family: 'Source Sans Pro';
      text-align: right;
      font-style: normal;
      color: #666666;
      .user-name {
        font-weight: 'bold';
        font-size: 15px;
        line-height: 19px;
      }
      .user-email {
        font-family: 'Source Sans Pro';
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
      }
    }

    .user-avatar {
      height: rem(30);
      width: rem(30);
      margin-right: rem(20);
      border-radius: 50%;
      border: 2px solid;
    }
  }
}
